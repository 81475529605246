import * as React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';

import '../styles/product.scss';

const ProductPage = () => {
  return (
    <Layout>
      <SEO
        title="Enhanced Efficiency Fertilizers | Crystal Green | Ostara"
        description="Optimize plant nutrition by increasing uptake and reducing runoff with enhanced efficiency fertilizers designed for today's data-driven and environmentally aware growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Enhanced Efficiency Fertilizers"
      />
      <main className="product-page">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 10, 10]} maxW="50rem" spacing={6}>
                  <h1>Enhanced Efficiency Fertilizers</h1>
                  <p>
                    The only phosphate fertilizers that respond to crop demand
                    for nutrients and designed to improve yield and reducing
                    nutrient loss to protect the environment.
                  </p>
                  <div className="button-wrappers">
                    <CustomButton
                      to="/product/crystal-green"
                      marginRight={5}
                      marginBottom={5}
                    >
                      CRYSTAL GREEN
                    </CustomButton>
                    <CustomButton
                      variant="crystal"
                      to="/product/crystal-green-pearl"
                      className="button-crystal"
                      marginRight={5}
                      marginBottom={5}
                    >
                      CRYSTAL GREEN PEARL
                    </CustomButton>
                  </div>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/new-product-banner.png"
                    alt="Ostara hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>
        <Box w="100%" p={4} className="product-body-container">
          <Flex direction="column">
            <Container
              marginTop={[10, 10, 10, 150]}
              maxW="container.lg"
              className="product-body-content"
            >
              <h3>
                Experience the Wide-Ranging Benefits of Ostara’s Enhanced
                Efficiency Fertilizers
              </h3>
              <p>
                Explore one-of-a-kind phosphate fertilizers that prevent
                nutrient loss to soil fixation, runoff and leaching for greater
                yield results.
              </p>
            </Container>
          </Flex>
          <Container className="product-key-line" maxW={818} />
          <Container className="product-container" maxW={1540}>
            <SimpleGrid columns={{ md: 1, lg: 2 }} spacing={10}>
              <Flex className="product-item" bg="#044606" direction="column">
                <Flex
                  bg="#044606"
                  direction="column"
                  align="center"
                  className="product-item--card"
                >
                  <div>
                    <h3>Crystal Green Fertilizer</h3>
                    <p>
                      A granular phosphate fertilizer with Crop Driven Release™
                      to continuously provide phosphate, along with nitrogen and
                      magnesium, to supply crop demand for nutrients.
                    </p>
                  </div>
                  <CustomButton maxW={180}>
                    <Link to="/product/crystal-green">LEARN MORE</Link>
                  </CustomButton>
                </Flex>
                <StaticImage
                  placeholder="transparent"
                  src="../images/fertilizers-min.jpg"
                  alt="Ostara logo"
                />
              </Flex>
              <Flex className="product-item crystal" direction="column">
                <Flex
                  direction="column"
                  align="center"
                  className="product-item--card"
                >
                  <div>
                    <h3>Crystal Green Pearl Fertilizer</h3>
                    <p>
                      Optimize crop nutrition with this environmentally
                      responsible plant available granular fertilizer
                      manufactured from{' '}
                      <Link to="/technology">recovered phosphorus</Link> that
                      also reduces soil tie-up, runoff and leaching.
                    </p>
                  </div>
                  <CustomButton maxW={180}>
                    <Link to="/product/crystal-green-pearl">LEARN MORE</Link>
                  </CustomButton>
                </Flex>
                <StaticImage
                  placeholder="transparent"
                  src="../images/CG-NXT_fertilizer-in-hand.jpeg"
                  alt="Ostara logo"
                />
              </Flex>
            </SimpleGrid>
          </Container>
        </Box>
        <Box p={4}>
          <Container
            maxW="container.lg"
            className="product-body-hero-container"
          >
            <Heading as="h2">
              Which Fertilizer Should You Choose? Either!
            </Heading>
            <p>
              Both Crystal Green and Crystal Green Pearl fertilizers offer the
              same agronomic advantages and yield benefits while maintaining the
              same percentage of phosphate, magnesium and nitrogen. The
              difference is: Crystal Green is manufactured in Florida and
              Missouri whereas Crystal Green Pearl utilizes excess phosphorus
              recovered from wastewater using our unique{' '}
              <Box as="span" textDecoration="underline">
                <Link to="/solutions">nutrient recovery solutions</Link>
              </Box>
              .
            </p>
          </Container>
        </Box>
        <Container className="body-cta-container" maxW={1400}>
          <Box className="body-cta" lineHeight={1.2}>
            <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default ProductPage;
